import { CheckOutlined } from '@ant-design/icons';
import { Button, List, Typography } from 'antd';
import { ReactNode } from 'react';

type CheckboxOptionItem = {
  key: string;
  label: string;
  value: string;
  children?: CheckboxOptionItem[];
};
type RightSideCheckboxProps = {
  options: CheckboxOptionItem[];
  prefix?: ReactNode;
  childrenPrefix?: ReactNode;
  onClick: (value: string) => void;
  value?: string[];
};

const RightSideCheckbox = ({ prefix, childrenPrefix, options, onClick, value }: RightSideCheckboxProps) => {
  return (
    <List
      dataSource={options}
      rowKey={(item) => item.key}
      renderItem={(item) => {
        const selected = value?.includes(item.value);
        return (
          <>
            <List.Item className='border-0 px-0 py-[6px]'>
              <div className='flex gap-x-[6px]'>
                {prefix}
                <Typography.Text>{item.label}</Typography.Text>
              </div>
              <Button
                shape='circle'
                type={selected ? 'primary' : 'default'}
                className={selected ? '' : 'text-subTextColor hover:text-colorPrimaryActive'}
                onClick={() => onClick(item.value)}
              >
                <CheckOutlined />
              </Button>
            </List.Item>
            {item.children && item.children.length > 0 && (
              <div className='pl-6'>
                <RightSideCheckbox
                  options={item.children}
                  prefix={childrenPrefix}
                  childrenPrefix={childrenPrefix}
                  onClick={onClick}
                  value={value}
                />
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default RightSideCheckbox;
