import { Skeleton } from 'antd';
import { InfiniteScroll, InputSearchV2 } from 'components/common';
import { RightSideCheckbox } from 'components/common/checkbox';
import { usePermissionsOptions } from 'hooks';
import { permissionsMessages } from 'messages';
import { useState } from 'react';
import { FindAllPermissionDto, PermissionCompactDto } from 'types';

type CheckboxPermissionsProps = Omit<FindAllPermissionDto, 'keyword' | 'pageIndex' | 'pageSize'> & {
  value?: PermissionCompactDto[];
  onChange: (value: PermissionCompactDto[]) => void;
  maxHeight?: string | number | undefined;
};

const CheckboxPermissions = ({ value, onChange, maxHeight }: CheckboxPermissionsProps) => {
  const [keyword, setKeyword] = useState('');

  const { permissionsOptions, handleLoadMore, isLoading, isFetching, hasMore, resetPage } = usePermissionsOptions({
    keyword
  });

  const handleSearch = (val: string) => {
    resetPage();
    setKeyword(val);
  };

  const formatData = (data: PermissionCompactDto[]): any[] => {
    if (data.length === 0) return [];

    return data.map((item) => ({
      key: item.permissionId.toString(),
      value: item.permissionId.toString(),
      label: item.name
    }));
  };

  const handleChange = (selectedValue: number) => {
    const isExists = value && value?.find((p) => p.permissionId === selectedValue);
    if (isExists) {
      onChange(value.filter((value) => value.permissionId !== selectedValue));
    } else {
      const selectedPermission = permissionsOptions.find((permission) => permission.permissionId === selectedValue);
      if (selectedPermission) onChange([...(value || []), selectedPermission]);
    }
  };
  return (
    <div>
      <InputSearchV2
        className='mb-2'
        placeholder={permissionsMessages.name}
        onChange={(e) => handleSearch(e.toString())}
      />
      <InfiniteScroll
        loader={<Skeleton active={isLoading} title={false} paragraph={{ rows: 5 }} />}
        hasMore={hasMore}
        next={handleLoadMore}
        isLoading={isLoading || isFetching}
      >
        <RightSideCheckbox
          options={formatData(permissionsOptions)}
          onClick={(value) => handleChange(Number(value))}
          value={value?.map((item) => item.permissionId.toString())}
        />
      </InfiniteScroll>
    </div>
  );
};

export default CheckboxPermissions;
