import { Button, Form, FormInstance, Layout, List, Spin, TabsProps, Tooltip, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { UserGroupFilledIcon } from 'assets';
import { Empty, TabButtons } from 'components/common';
import { FormItem } from 'components/common/form-item';
import { SelectPermissions } from 'components/permissions';
import { SelectRolesOptions } from 'components/roles';
import { uniqBy } from 'lodash';
import { messages, permissionsMessages, rolesMessages, usersMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  useGetUserPermissionsQuery,
  useGetUserQuery,
  useLazyGetPermissionsOptionsQuery,
  useUpdateUserMutation
} from 'services';
import { PermissionCompactDto, RoleCompactDto } from 'types';

enum TABS {
  PERMISSIONS = 'permissions',
  ROLES = 'roles'
}

export type UserUpdatePermissionsFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onChangeSuccess?: () => void;
  userId?: number;
};

export type UserUpdatePermissionsFormRefProps = {
  form: FormInstance<UserUpdatePermissionsFormType>;
  isLoading: boolean;
};
export type UserUpdatePermissionsFormType = {
  permissions: PermissionCompactDto[];
  roles: RoleCompactDto[];
};
const UserUpdatePermissionsForm = forwardRef<UserUpdatePermissionsFormRefProps, UserUpdatePermissionsFormProps>(
  ({ userId, onChangeLoading, onChangeSuccess }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingUpdate
    }));

    const { data: userInfoRes } = useGetUserQuery(userId!, {
      skip: !userId
    });
    const { data: userRes, isLoading } = useGetUserPermissionsQuery(userId!, {
      skip: !userId
    });
    const [getRolePermissions, { data: rolePermissionsRes }] = useLazyGetPermissionsOptionsQuery();

    const userInfo = userInfoRes?.data;
    const userPermissions = userRes?.data;
    const rolePermissions = rolePermissionsRes?.data.rows || [];
    const userGroupPermissions = userPermissions?.userGroup?.permissions || [];
    const userGroupPermissionIds = userGroupPermissions.map((p) => p.permissionId);
    const [selectedTab, setSelectedTab] = useState<TABS>(TABS.ROLES);

    const [form] = Form.useForm<UserUpdatePermissionsFormType>();
    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateUserMutation();
    const onFinish = (values: UserUpdatePermissionsFormType) => {
      if (!userId) return;
      onUpdate({
        userId,
        permissionIds: values.permissions.map((o) => o.permissionId),
        roleIds: values.roles.map((o) => o.roleId)
      })
        .unwrap()
        .then(() => {
          onChangeSuccess?.();
        });
    };
    useEffect(() => {
      if (userPermissions && userId) {
        form.setFieldsValue({
          permissions: userPermissions.permissions,
          roles: userPermissions.roles
        });
        if (userPermissions.roles && userPermissions.roles.length > 0) {
          getRolePermissions({
            isLoadAll: true,
            roleIds: userPermissions.roles.map((role) => role.roleId)
          });
        }
      }
    }, [userPermissions, userId]);

    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingUpdate);
      }
    }, [onChangeLoading, isLoadingUpdate]);

    const permissions: PermissionCompactDto[] = Form.useWatch('permissions', form) || [];
    const handleRemovePermission = (record: PermissionCompactDto) => {
      form.setFieldValue(
        'permissions',
        permissions.filter((o) => o.permissionId !== record.permissionId)
      );
    };

    const handleAddPermission = (record: DefaultOptionType & PermissionCompactDto) => {
      form.setFieldValue('permissions', [
        {
          permissionId: record.permissionId,
          name: record.name,
          code: record.code
        },
        ...permissions.filter((o) => o.permissionId !== record.permissionId)
      ]);
    };

    const roles: RoleCompactDto[] = Form.useWatch('roles', form) || [];
    const handleRemoveRole = (record: RoleCompactDto) => {
      const newRoles = roles.filter((o) => o.roleId !== record.roleId);
      form.setFieldValue('roles', newRoles);
      getRolePermissions({
        isLoadAll: true,
        roleIds: newRoles.length > 0 ? newRoles.map((role) => role.roleId) : [0]
      });
    };

    const handleAddRole = (record: DefaultOptionType & RoleCompactDto) => {
      const newRoles = [
        {
          roleId: record.roleId,
          name: record.name,
          code: record.code,
          permissions: record.permissions
        },
        ...roles.filter((o) => o.roleId !== record.roleId)
      ];
      form.setFieldValue('roles', newRoles);
      getRolePermissions({
        isLoadAll: true,
        roleIds: newRoles.length > 0 ? newRoles.map((role) => role.roleId) : [0]
      });
    };

    const tabItems: TabsProps['items'] = [
      {
        key: TABS.ROLES,
        label: rolesMessages.title
      },
      {
        key: TABS.PERMISSIONS,
        label: permissionsMessages.title
      }
    ];

    const combinedPermissions = uniqBy([...userGroupPermissions, ...rolePermissions, ...permissions], 'permissionId');

    return (
      <Layout className='min-h-screen'>
        <Layout.Sider className='min-w-[348px] bg-colorBgBody px-6 pb-5 pt-16'>
          <Typography.Title level={4} className='mb-2 text-2.5xl'>
            {userInfo?.fullName}
          </Typography.Title>
          <Typography.Paragraph type='secondary' className='mb-0'>
            {userInfo?.email}
          </Typography.Paragraph>
          <Typography.Paragraph type='secondary' className='mb-8'>
            {userInfo?.organizationUnit?.name}
          </Typography.Paragraph>
          <Typography.Title level={5} className='mb-4 text-lg'>
            {usersMessages.grantPermissions}
          </Typography.Title>
          {combinedPermissions.length > 0 && (
            <List
              className='-mx-6 max-h-[calc(100vh-240px)] overflow-auto px-6'
              dataSource={combinedPermissions}
              rowKey={(item) => item.permissionId}
              split={false}
              renderItem={(item) => (
                <List.Item className='flex gap-x-2 py-[10px]'>
                  <Typography.Paragraph className='mb-0' ellipsis={{ rows: 1 }}>
                    {item.name}
                  </Typography.Paragraph>

                  {userGroupPermissionIds.includes(item.permissionId) ? (
                    <Tooltip
                      placement='topLeft'
                      arrow={{ pointAtCenter: true }}
                      title={
                        <Typography.Text className='text-xs text-colorTextContrast'>{`${usersMessages.permissionFromGroup} ${userPermissions?.userGroup?.name}`}</Typography.Text>
                      }
                    >
                      <UserGroupFilledIcon className='text-iconColor' />
                    </Tooltip>
                  ) : undefined}
                </List.Item>
              )}
            />
          )}
        </Layout.Sider>
        <Layout className='bg-colorBgContainer px-6 pb-5 pt-16'>
          <Typography.Title className='mb-2 text-2.5xl'>{usersMessages.permissions}</Typography.Title>
          <Typography.Paragraph type='secondary' className='mb-6'>
            {messages.chooseOneOrMorePerrmission}
          </Typography.Paragraph>
          <Form
            scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
            labelAlign='right'
            labelCol={{
              flex: '150px'
            }}
            requiredMark={false}
            form={form}
            name='userUpdatePermissionForm'
            onFinish={onFinish}
            layout='horizontal'
          >
            <Spin spinning={isLoading}>
              <FormItem<UserUpdatePermissionsFormType> name='permissions' noStyle />
              <FormItem<UserUpdatePermissionsFormType> name='roles' noStyle />
              <TabButtons
                className='pb-4'
                defaultActiveKey={TABS.PERMISSIONS}
                items={tabItems}
                onClick={(key) => setSelectedTab(key as TABS)}
                activeKey={selectedTab}
              />
              {selectedTab === TABS.PERMISSIONS && (
                <>
                  <SelectPermissions
                    onSelect={(_value, record) => {
                      const item = record as DefaultOptionType & PermissionCompactDto;
                      handleAddPermission(item);
                    }}
                    placeholder={permissionsMessages.name}
                    size='large'
                    className='mb-6 w-full'
                    value={null}
                  />
                  <List
                    itemLayout='horizontal'
                    dataSource={permissions}
                    rowKey={(item) => item.permissionId!}
                    locale={{
                      emptyText: <Empty />
                    }}
                    renderItem={(item, index) => (
                      <List.Item
                        actions={[
                          <Button
                            danger
                            type='text'
                            size='small'
                            onClick={() => {
                              handleRemovePermission(item);
                            }}
                            className='text-sm'
                          >
                            {messages.deleteButtonText}
                          </Button>
                        ]}
                      >
                        <Typography.Text>{item.name}</Typography.Text>
                      </List.Item>
                    )}
                  />
                </>
              )}
              {selectedTab === TABS.ROLES && (
                <>
                  <SelectRolesOptions
                    onSelect={(_value, record) => {
                      const item = record as DefaultOptionType & RoleCompactDto;
                      handleAddRole(item);
                    }}
                    placeholder={rolesMessages.name}
                    size='large'
                    className='mb-6 w-full'
                    value={null}
                  />
                  <List
                    itemLayout='horizontal'
                    dataSource={roles}
                    rowKey={(item) => item.roleId!}
                    locale={{
                      emptyText: <Empty />
                    }}
                    renderItem={(item, index) => (
                      <List.Item
                        actions={[
                          <Button
                            danger
                            type='text'
                            size='small'
                            onClick={() => {
                              handleRemoveRole(item);
                            }}
                            className='text-sm'
                          >
                            {messages.deleteButtonText}
                          </Button>
                        ]}
                      >
                        <Typography.Text>{item.name}</Typography.Text>
                      </List.Item>
                    )}
                  />
                </>
              )}
            </Spin>
            <div className='-mx-6 mt-2 border-t px-6 py-5'>
              <Button onClick={() => form.submit()} className='float-right' loading={isLoadingUpdate} type='primary'>
                {messages.saveButtonText}
              </Button>
            </div>
          </Form>
        </Layout>
      </Layout>
    );
  }
);
export default UserUpdatePermissionsForm;
