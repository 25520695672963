import { CalendarColoredIcon, DollarCircleColoredIcon, DollarColoredIcon } from 'assets';

export const CUSTOMER_SUPPORT_DEFAULT_ICONS = [
  {
    key: 'calendar',
    icon: CalendarColoredIcon,
    path: '/svg/calendar-colored.svg'
  },
  {
    key: 'dollar',
    icon: DollarColoredIcon,
    path: '/svg/dollar-colored.svg'
  },
  {
    key: 'dollar-circle',
    icon: DollarCircleColoredIcon,
    path: '/svg/dollar-circle-colored.svg'
  }
];
